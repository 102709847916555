import * as React from 'react';
import {
  ContentViewer,
  ContentViewerProps,
} from '@wix/social-groups-common/dist/src/components/ContentEditor/ContentViewer';
import getTheme from './theme';
import { allTypeMappers } from '@wix/social-groups-common/dist/src/components/ContentEditor/plugins/typeMappers/typeMappers';

const typeMappersStorage = {
  typeMappers: allTypeMappers(),
  asyncLoaded: false,
};
export class ThemedContentViewer extends React.Component<ContentViewerProps> {
  static displayName = 'ThemedContentViewer';
  state = {
    typeMappersLoaded: false,
  };
  componentDidMount(): void {
    if (!typeMappersStorage.asyncLoaded) {
      import(
        /*webpackChunkName: "asyncTypeMappers"*/ '@wix/social-groups-common/dist/src/components/ContentEditor/plugins/typeMappers/asyncTypeMappers'
      )
        .then((module: any) => {
          const {
            videoTypeMapper,
            galleryTypeMapper,
            pollTypeMapper,
          } = module.default;
          typeMappersStorage.typeMappers = allTypeMappers().concat([
            videoTypeMapper,
            galleryTypeMapper,
            pollTypeMapper,
          ]);
          typeMappersStorage.asyncLoaded = true;
          this.setState({ typeMappersLoaded: true });
        })
        .catch(e => {
          return console.log('Error loading async typeMappers');
        });
    }
  }
  render() {
    const theme = getTheme();
    return (
      <ContentViewer
        {...this.props}
        helpers={this.props.helpers}
        theme={theme}
        typeMappers={typeMappersStorage.typeMappers}
      />
    );
  }
}
