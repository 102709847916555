import * as React from 'react';
import { EmptyState } from '../../EmptyState';
import { InjectedTranslateProps, Trans, translate } from 'react-i18next';

interface SomethingWentWrongProps {
  isOwner: boolean;
}

const SomethingWentWrongComponent: React.FC<SomethingWentWrongProps &
  InjectedTranslateProps> = ({ t, isOwner }) => {
  return (
    <EmptyState
      title={t('groups-web.discussion.error-state.title')}
      content={
        <Trans
          i18nKey={
            isOwner
              ? 'groups-web.discussion.error-state.owner.message'
              : 'groups-web.discussion.error-state.member.message'
          }
          components={[
            <span key="0">Try reloading</span>,
            <br key="1" />,
            <span key="2">contact</span>,
          ]}
        />
      }
      button={{
        label: t('groups-web.discussion.error-state.cta'),
        onClick: () => window.location.reload(),
      }}
    />
  );
};

export const SomethingWentWrong = translate()(
  SomethingWentWrongComponent,
) as React.ComponentType<SomethingWentWrongProps>;
