import * as React from 'react';
import { AppSettingsContextValue } from './AppSettingsContextValue';

export const AppSettingsContext = React.createContext<AppSettingsContextValue>({
  text: null,
  generalLayout: null,
  mediaLayout: null,
  aboutDisplay: null,
  discussionsDisplay: null,
  mediaDisplay: null,
  membersDisplay: null,
  design: null,
});
