import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
export enum Tab {
  DISCUSSION = 'discussion',
  MEDIA = 'media',
  MEMBERS = 'members',
  ABOUT = 'about',
}

export const TabKeyMap = {
  [Tab.DISCUSSION]: ApiTypes.v1.GroupAppKey.FEED_APP,
  [Tab.ABOUT]: ApiTypes.v1.GroupAppKey.ABOUT_APP,
  [Tab.MEMBERS]: ApiTypes.v1.GroupAppKey.MEMBERS_APP,
  [Tab.MEDIA]: ApiTypes.v1.GroupAppKey.GALLERY_APP,
};
