import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { Text } from 'wix-ui-tpa/Text';
import { FeedItem, FeedItemProps } from '../FeedItem';
import styles from './PinnedPost.st.css';
import { PinIcon } from '../../../icons/PinIcon';
import { Box } from '@wix/social-groups-common/dist/src/components';

interface PinnedPostProps extends FeedItemProps {}

const PinnedPostComponent: React.FC<PinnedPostProps &
  InjectedTranslateProps> = ({ t, ...feedItemProps }) => {
  return (
    <div {...styles('root', {})}>
      <Box className={styles.header}>
        <PinIcon className={styles.icon} width={18} height={17} />
        <Text className={styles.headerText}>
          {t('groups-web.discussion.feed.pined-post')}
        </Text>
      </Box>
      <FeedItem {...feedItemProps} />
    </div>
  );
};

export const PinnedPost = translate()(
  PinnedPostComponent,
) as React.ComponentType<PinnedPostProps>;
