import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';
//WIX-UI-TPA
import { ALIGNMENT, Tabs, VARIANT } from 'wix-ui-tpa/Tabs';
// ‼️ All long imports are intentional
//COMMON
import {
  AppToasts,
  withAppToasts,
} from '@wix/social-groups-common/dist/src/components/AppToats';
import { ModalParentNodeRefProvider } from '@wix/social-groups-common/dist/src/components/Modal/ModalParentNodeRefContext';
import { Breadcrumbs } from '@wix/social-groups-common/dist/src/components/Breadcrumbs';
import { Loading } from '@wix/social-groups-common/dist/src/components/Loader/Loading';
import { WithAppToastsProps } from '@wix/social-groups-common/dist/src/types';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  withBiLogger,
  WithBiLoggerProps,
  tryToCallBi,
} from '@wix/social-groups-common/dist/src/context';
//API
import { ApiTypes, GroupAppsMap } from '@wix/social-groups-api/dist/src/types';

import { Page, Pages } from '../Page';
import { Tab, TabKeyMap } from '../../controllers/types';
import styles from './Group.st.css';
import { Header } from './Header';
import { Private } from './Discussion/Private';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from './Context/withTpaComponentsConfig';
import { TABS_HOOK } from '../../config/constants';
import { WithGroupProps } from './Context/GroupContext';
import {
  WithGroupActionProps,
  WithGroupActions,
} from './Context/GroupActionsContext';
import { withSiteMembers, WithSiteMembers } from './Context/withSiteMembers';
import { Discussion } from './Discussion/Discussion';
import { Media } from './Media/Media';
import { AboutPage } from './About/AboutPage';
import { Members } from './Members/Members';

export interface GroupProps {
  canSeeGroup: boolean;
  activeTab: string;
  feedItemId: string;
  ready: boolean;
  apps: GroupAppsMap;

  changeTab(tab: string);
}

export interface GroupState {
  forceCreatePost: boolean;
}

const TabTitles = Object.values(Tab) as string[];

function getCustomTabName(title, apps) {
  try {
    const appKey = TabKeyMap[title];
    const { customName } = apps[appKey] || ({} as any);
    return customName;
  } catch (e) {}
}

class GroupComponent extends React.Component<
  GroupProps &
    WithGroupProps &
    WithSiteMembers &
    InjectedExperimentsProps &
    InjectedTranslateProps &
    WithAppToastsProps &
    WithTpaComponentsConfigProps &
    WithGroupActionProps &
    WithBiLoggerProps,
  GroupState
> {
  readonly ref = React.createRef<HTMLDivElement>();

  readonly state: GroupState = {
    forceCreatePost: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentSiteMember && !this.props.currentSiteMember) {
      this.props.getFollowingMembers(nextProps.currentSiteMember.id);
    }
  }

  render() {
    const {
      activeTab,
      changeTab,
      ready,
      t,
      toasts,
      mobile,
      group,
      goToGroupList,
      ...rest
    } = this.props;

    const tabs = this.getTabs();

    const activeTabIndex = Math.max(
      this.getVisibleTabs().indexOf(activeTab),
      0,
    );

    return (
      <ModalParentNodeRefProvider value={this.ref.current}>
        <div ref={this.ref} {...styles('root', { mobile }, rest)}>
          {ready ? (
            <>
              <AppToasts t={t} {...toasts} />
              <Breadcrumbs
                className={styles.breadcrumbs}
                items={[
                  {
                    label: t('groups-web.breadcrumbs.group-list'),
                    action() {
                      // need this to omit event passed
                      goToGroupList();
                    },
                  },
                  {
                    label:
                      group && group.details && group.details.title
                        ? group.details.title
                        : t('groups-web.breadcrumbs.group-title-default'),
                  },
                ]}
              />
              <Header />
              <div className={styles.tabsWrapper}>
                <Tabs
                  data-hook={TABS_HOOK}
                  activeTabIndex={activeTabIndex}
                  alignment={ALIGNMENT.left}
                  variant={mobile ? VARIANT.fullWidth : VARIANT.fit}
                  onTabClick={this.handleTabClick}
                  items={tabs}
                  {...styles(styles.tabs)}
                />
              </div>
              {this.renderPages()}
            </>
          ) : (
            <Loading />
          )}
        </div>
      </ModalParentNodeRefProvider>
    );
  }

  private renderProtectedPage(page: JSX.Element) {
    const { canSeeGroup } = this.props;
    return canSeeGroup ? page : <Private />;
  }

  private renderPages() {
    const { activeTab, apps, feedItemId } = this.props;
    const { forceCreatePost } = this.state;
    let membersInstalled = false;
    let mediaInstalled = false;

    if (apps) {
      const memberApp = apps[ApiTypes.v1.GroupAppKey.MEMBERS_APP];
      membersInstalled = memberApp && memberApp.installed;
      const mediaApp = apps[ApiTypes.v1.GroupAppKey.GALLERY_APP];
      mediaInstalled = mediaApp && mediaApp.installed;
    }

    return (
      <Pages activePage={activeTab}>
        <Page name={Tab.DISCUSSION}>
          {this.renderProtectedPage(
            <Discussion
              feedItemId={feedItemId}
              forceCreatePost={forceCreatePost}
              resetForceCreatePost={this.resetForceCreatePost}
            />,
          )}
        </Page>
        <Page name={Tab.ABOUT}>
          <AboutPage />
        </Page>
        {membersInstalled && (
          <Page name={Tab.MEMBERS}>
            {this.renderProtectedPage(<Members withMoreActions={true} />)}
          </Page>
        )}
        {mediaInstalled && (
          <Page name={Tab.MEDIA}>
            {this.renderProtectedPage(
              <Media onCreatePostClick={this.handleCreatePostFromMediaTab} />,
            )}
          </Page>
        )}
      </Pages>
    );
  }

  private readonly handleCreatePostFromMediaTab = () => {
    this.setState({ forceCreatePost: true });
    this.props.changeTab(Tab.DISCUSSION);
  };

  private readonly resetForceCreatePost = () => {
    this.setState({ forceCreatePost: false });
  };

  private getCustomTabName(title, apps) {
    try {
      const appKey = TabKeyMap[title];
      const { customName } = apps[appKey] || ({} as any);
      return customName;
    } catch (e) {}
  }

  private readonly showTab = key => {
    try {
      const { apps } = this.props;
      const appKey = TabKeyMap[key];
      return apps[appKey].installed;
    } catch (e) {}
    return false;
  };

  private getTabs() {
    const { t, apps } = this.props;
    return this.getVisibleTabs().map(title => {
      const customName = getCustomTabName(title, apps);
      return {
        title: customName || t(`groups-web.${title}`),
      };
    });
  }

  private getVisibleTabs() {
    return TabTitles.filter(this.showTab);
  }

  handleTabClick = selectedTabIndex => {
    const tabs = this.getVisibleTabs();
    this.props.changeTab(tabs[selectedTabIndex]);

    tryToCallBi(async () => {
      await this.props.biLogger.groupsTabClicked({
        group_id: this.props.group.groupId,
        origin: 'tabs',
        name: tabs[selectedTabIndex],
      });
    });
  };
}

const enhance = compose(
  translate(),
  withAppToasts,
  withExperiments,
  withTpaComponentsConfig,
  WithGroupActions,
  withSiteMembers,
  withBiLogger,
);

export const Group = enhance(GroupComponent);
