import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import {
  Dialog,
  DialogProps,
} from '@wix/social-groups-common/dist/src/components';
import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import styles from './AddOrRemoveAdminRole.st.css';

interface AddOrRemoveAdminRoleDialogComponentProps {
  description: string;
  memberFullName: string;
  memberImage: string;
  onConfirmClick();
}

type AddOrRemoveAdminRoleDialogProps = DialogProps &
  AddOrRemoveAdminRoleDialogComponentProps;
const adminPermissionKeys = [
  'groups-web.group.actions.admin-role-description.posts',
  'groups-web.group.actions.admin-role-description.comments',
  'groups-web.group.actions.admin-role-description.members',
];

const AddOrRemoveAdminRoleDialogComponent: React.FC<AddOrRemoveAdminRoleDialogProps &
  InjectedTranslateProps> = props => {
  const {
    t,
    onConfirmClick,
    isOpen,
    memberFullName,
    memberImage,
    onRequestClose,
    okLabel,
    title,
    description,
  } = props;

  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      cancelLabel={t('groups-web.cancel')}
      okLabel={okLabel}
      onOkClick={onConfirmClick}
    >
      <div className={styles.header}>
        <Avatar
          size={AvatarSize.large}
          src={memberImage}
          name={memberFullName}
        />
        <Text typography={TYPOGRAPHY.runningText} className={styles.memberName}>
          {memberFullName}
        </Text>
        <Text typography={TYPOGRAPHY.largeTitle} tagName={'h1'}>
          {title}
        </Text>
      </div>
      <div>
        <Text
          typography={TYPOGRAPHY.runningText}
          className={styles.description}
          tagName="div"
        >
          {description}
        </Text>

        <ul className={styles.list}>
          {adminPermissionKeys.map(translationKey => (
            <Text
              key={translationKey}
              typography={TYPOGRAPHY.runningText}
              tagName="li"
            >
              {t(translationKey)}
            </Text>
          ))}
        </ul>
      </div>
    </Dialog>
  );
};

export const AddOrRemoveAdminRoleDialog = translate()(
  AddOrRemoveAdminRoleDialogComponent,
) as React.ComponentType<AddOrRemoveAdminRoleDialogProps>;
AddOrRemoveAdminRoleDialog.displayName = 'AddOrRemoveAdminRoleDialog';
