import * as React from 'react';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';
//COMMON
import { pluginsConfig } from '@wix/social-groups-common/dist/src/components/ContentEditor/plugins/config/pluginsConfig';
import { Spinner } from '@wix/social-groups-common/dist/src/components/Spinner';
import { RawDraftContentState } from '@wix/social-groups-common/dist/src/components/ContentEditor/types';
import { PluginTypes } from '@wix/social-groups-common/dist/src/components/ContentEditor/plugins/pluginTypes';
import { compose } from '@wix/social-groups-common/dist/src/compose';

import {
  withAppData,
  WithAppDataProps,
} from '../../Group/Context/AppData/withAppData';
import { WithGroup, WithGroupProps } from '../Context/GroupContext';
import { MembersActions } from '../Context/SiteMembers';
import { MentionedMember } from './MentionedMember';
import { ThemedContentViewer } from '../../ContentEditor/ContentViewer';

import * as getImagesData from 'wix-rich-content-fullscreen/dist/lib/getImagesData.cjs';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../Context/withTpaComponentsConfig';
import styles from './PostContentViewer.st.css';

const Fullscreen = React.lazy(() => {
  return import(
    /* webpackChunkName: "asyncRichContent" */ '../../ContentEditor/asyncRichContent'
  )
    .then(module => ({ default: module.Fullscreen }))
    .catch(e => ({ default: null }));
});

interface PostContentViewerProps {
  contentState: RawDraftContentState<any>;

  handleUserValidationRequest?(resolve: Function, reject: Function);
}

type Props = PostContentViewerProps &
  WithGroupProps &
  MembersActions &
  WithTpaComponentsConfigProps &
  InjectedExperimentsProps &
  WithAppDataProps;

interface State {
  expandIsOpened: boolean;
  expandIndex: number;
  renderFullScreen: boolean;
}

class PostContentViewerComponent extends React.Component<Props, State> {
  readonly state: State = {
    expandIsOpened: false,
    expandIndex: -1,
    renderFullScreen: false,
  };
  imagesData: { images: {}[]; imageMap?: number[] } = { images: [] };
  static displayName = 'PostContentViewerComponent';

  componentDidMount() {
    this.imagesData = getImagesData(this.props.contentState);
  }

  onExpandOpen = (entityIndex, innerIndex = 0) => {
    const { imageMap } = this.imagesData;
    this.setState({
      expandIsOpened: true,
      expandIndex: imageMap[entityIndex] + innerIndex,
      renderFullScreen: true,
    });
  };
  onExpandClose = () =>
    this.setState({ expandIsOpened: false, expandIndex: -1 });

  config = {
    ...pluginsConfig,
    [PluginTypes.Mention]: {
      onMentionClick: (mention: MentionedMember) =>
        this.props.openUserProfile(mention.id),
      getMentionLink: () => {},
    },
    [PluginTypes.Image]: {
      onExpand: this.onExpandOpen,
    },
    [PluginTypes.Gallery]: {
      onExpand: this.onExpandOpen,
    },
  };

  verifyUserMembership = () => {
    const { handleUserValidationRequest } = this.props;

    return new Promise(handleUserValidationRequest);
  };

  render() {
    const { contentState, instance, members, mobile } = this.props;
    const { expandIndex, expandIsOpened, renderFullScreen } = this.state;
    const { images } = this.imagesData;
    // TODO investigate why gallery broke for galleryLayout === 3
    for (const entityId in contentState.entityMap) {
      const { type, data } = contentState.entityMap[entityId];

      if (type !== PluginTypes.Gallery) {
        continue;
      }

      contentState.entityMap[entityId].data.styles = {
        ...data.styles,
        galleryLayout: 2,
      };
    }

    return (
      <div {...styles('root', { mobile })}>
        <ThemedContentViewer
          config={{
            ...this.config,
            [PluginTypes.Poll]: {
              siteToken: instance,
              getSiteMembers: () => members,
              validateUser: this.verifyUserMembership,
            },
          }}
          initialState={contentState}
        />
        {renderFullScreen && (
          <React.Suspense fallback={<Spinner />}>
            <Fullscreen
              isOpen={expandIsOpened}
              index={expandIndex}
              images={images}
              onClose={this.onExpandClose}
              target={document.body}
            />
          </React.Suspense>
        )}
      </div>
    );
  }
}

const enhance = compose(
  WithGroup,
  withAppData,
  withExperiments,
  withTpaComponentsConfig,
);

export const PostContentViewer = enhance(
  PostContentViewerComponent,
) as React.ComponentType<PostContentViewerProps>;
PostContentViewer.displayName = 'PostContentViewer';
