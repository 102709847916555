import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { TPAModalProps } from '@wix/social-groups-common';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { memberWrapper } from '@wix/social-groups-api';
import { Grid } from 'wix-ui-tpa/Grid';

import {
  withSiteMembers,
  WithSiteMembers,
} from '../../Context/withSiteMembers';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../Context/withTpaComponentsConfig';
import { MembersModal } from '../MembersModal';

import { MemberCard } from '../../MemberCard/MemberCard';

import styles from './UsersJoinedModal.st.css';
import { FollowButton } from '../../FollowButton';

interface UserJoinedModalProps extends TPAModalProps {
  members: ApiTypes.v1.GroupMemberResponse[];
}

type UserJoinedModalComponentProps = UserJoinedModalProps &
  WithSiteMembers &
  WithTpaComponentsConfigProps &
  InjectedTranslateProps;

const UserJoinedModalComponent: React.FC<UserJoinedModalComponentProps> = props => {
  const { onRequestClose, isOpen, t, members, mobile } = props;

  return (
    <MembersModal onRequestClose={onRequestClose} isOpen={isOpen}>
      <MembersModal.Header>
        {t('groups-web.users-joined-popup.title')}
      </MembersModal.Header>
      <MembersModal.Content {...styles('contentWrapper')}>
        {members.map((m, i) => {
          const { name, imageUrl } = memberWrapper(m);
          return (
            <div
              key={m.siteMemberId}
              {...styles('memberCardWrapper', { mobile })}
            >
              <Grid.Item colSpan={1} rowSpan={1}>
                <MemberCard
                  name={name.nick || t('groups-web.member.anonymous')}
                  image={imageUrl}
                  withDivider={!mobile && i !== members.length - 1}
                  action={<FollowButton siteMemberId={m.siteMemberId} />}
                />
              </Grid.Item>
            </div>
          );
        })}
      </MembersModal.Content>
    </MembersModal>
  );
};

const enhance = compose(translate(), withSiteMembers, withTpaComponentsConfig);

export const UserJoinedModal = enhance(
  UserJoinedModalComponent,
) as React.ComponentType<UserJoinedModalProps>;
