import * as React from 'react';

export const EllipsesIcon: React.FC<React.SVGAttributes<
  SVGSVGElement
>> = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      d="M12.4 13.5c-.8 0-1.4-.7-1.4-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.6 1.5zm0-6.5c-.8 0-1.4-.7-1.4-1.5S11.7 4 12.5 4s1.5.7 1.5 1.5S13.3 7 12.4 7zm.2 10c.8 0 1.4.7 1.4 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.7-1.5 1.6-1.5z"
      fillRule="nonzero"
      opacity={0.8}
    />
  </svg>
);
