import * as React from 'react';
import { InjectedTranslateProps } from 'react-i18next';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  getMembersLabelKey,
  MembersLabelTranslations,
} from '@wix/social-groups-common/dist/src/settings';

export interface MembersLabelProps extends InjectedTranslateProps {
  group: ApiTypes.v1.GroupResponse;
}

// WARNING!!!: changing keys here affects social-group keys
export const MembersLabelDict: MembersLabelTranslations = {
  Members: 'groups-web.members',
  Students: 'groups-web.members.students',
  Coworkers: 'groups-web.members.coworkers',
  Friends: 'groups-web.members.friends',
  Gamers: 'groups-web.members.gamers',
  Custom: 'groups-web.members.custom',
};

export function getMembersLabel(
  group: ApiTypes.v1.GroupResponse,
  t: Function,
): { key: string; value: string } {
  const membersLabelKey = getMembersLabelKey(group, MembersLabelDict);
  const groupWrapper = new GroupWrapper(group);
  const isCustom = MembersLabelDict.Custom === membersLabelKey;
  return {
    key: membersLabelKey,
    value: isCustom ? groupWrapper.getMembersName() : t(membersLabelKey),
  };
}
