import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { Text } from 'wix-ui-tpa/Text';
import { RoleBadges } from '@wix/social-groups-common/dist/src/components/RoleBadges';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import styles from './Details.st.css';
import {
  WithGroup,
  WithGroupProps,
  withAppSettings,
  WithAppSettingsProps,
} from '../Context';
import { MembersCount } from '../MembersCount';
import { getSettingsKeyFor } from '@wix/social-groups-common/dist/src/utils/utils';
import { withTPAConfig } from '@wix/social-groups-common';
import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';

export const DETAILS = 'DETAILS';
export const DETAILS_TITLE = `${DETAILS}_TITLE`;

// TODO: extract shared translations
export const DetailsComponent: React.FC<WithGroupProps &
  InjectedTranslateProps &
  TPAComponentsConfig &
  WithAppSettingsProps> = ({ mobile, group, t, appSettings }) => {
  const {
    details,
    relationship,
    roles,
    settings: { privacyLevel },
  } = group;

  const showMembersCountKey = getSettingsKeyFor('showMembersCount', mobile);

  const showMembersCount = appSettings.discussionsDisplay[showMembersCountKey];

  return (
    <div data-hook={DETAILS} {...styles(styles.root, { mobile })}>
      <div className={styles.titleAndRole}>
        <Text data-hook={DETAILS_TITLE} {...styles(styles.title)}>
          {details.title}
        </Text>
        <div className={styles.badgesSection}>
          <RoleBadges relationship={relationship} roles={roles} />
        </div>
      </div>
      <div className={styles.privacyAndMembers}>
        <Text {...styles(styles.groupInfoItem)}>
          {t(`groups-web.types.${privacyLevel.toLowerCase()}.name`)}
        </Text>
        {showMembersCount && (
          <>
            <Text {...styles(styles.groupInfoItem)}>&nbsp;·&nbsp;</Text>
            <MembersCount {...styles(styles.groupInfoItem)} />
          </>
        )}
      </div>
    </div>
  );
};
const enhance = compose(translate(), WithGroup, withAppSettings, withTPAConfig);

export const Details = enhance(DetailsComponent);
