import * as React from 'react';
import { GroupActionsConsumer } from './GroupActionsContext';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

export interface MembershipQuestionsAction {
  closeMembershipQuestionStep(): void;
}

export interface MembershipAction {
  joinGroup(
    membershipQuestionsAnswers?: ApiTypes.v1.MembershipQuestionAnswer[],
  ): void;
  leaveGroup(): void;
  withdrawJoinRequest(): void;
}

export interface MembershipChangeAction {
  changeMembership(
    biOrigin: string,
    membershipQuestionsAnswers?: ApiTypes.v1.MembershipQuestionAnswer[],
  ): void;
  openJoinDialog(): void;
}

export const withMembershipChangeAction = <P extends MembershipChangeAction>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> => props => (
  <GroupActionsConsumer>
    {({ changeMembership, openJoinDialog }) => (
      <WrappedComponent
        {...props}
        changeMembership={changeMembership}
        openJoinDialog={openJoinDialog}
      />
    )}
  </GroupActionsConsumer>
);
