import * as React from 'react';
import { GroupActionsConsumer } from './GroupActionsContext';

export interface InviteMembersProps {
  inviteMembersByEmail(emails: string[]): void;
}

export const withInviteMembers = <P extends InviteMembersProps>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> => props => (
  <GroupActionsConsumer>
    {actions => (
      <WrappedComponent
        {...props}
        inviteMembersByEmail={actions.inviteMembersByEmail}
      />
    )}
  </GroupActionsConsumer>
);
