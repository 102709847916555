import { NotificationSettingsMap } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import * as React from 'react';

export type GroupNotificationSettings = {
  [id in ApiTypes.v1.NotificationChannel]: NotificationSettingsMap;
};

interface NotificationActions {
  getNotificationSettings();
  setNotificationSettings(settings: GroupNotificationSettings);
}

export interface NotificationSettings {
  notificationSettings: GroupNotificationSettings;
  notificationActions: NotificationActions;
}

export const NotificationSettingsContext = React.createContext<
  NotificationSettings
>({
  notificationActions: undefined,
  notificationSettings: undefined,
});
NotificationSettingsContext.displayName = 'NotificationSettingsContext';
