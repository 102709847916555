import * as React from 'react';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { GroupActionsConsumer } from './GroupActionsContext';
import { UpdateProgress } from '@wix/social-groups-common/dist/src/components/ContentEditor/UpdateProgress';
export interface GroupUpdate {
  updateGroup(
    paths: string[],
    details?: ApiTypes.v1.GroupDetails,
    settings?: ApiTypes.v1.GroupSettings,
  );
  setEditMode(mode: UpdateProgress);
}

export interface UpdateContext {
  updateProgress: UpdateProgress;
}

export const withGroupUpdate = <P extends GroupUpdate>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<Omit<P, keyof GroupUpdate>> => props => (
  <GroupActionsConsumer>
    {({ updateGroup, setEditMode }) => (
      <WrappedComponent
        {...(props as P)}
        updateGroup={updateGroup}
        setEditMode={setEditMode}
      />
    )}
  </GroupActionsConsumer>
);
