import * as React from 'react';

import { I18nextProvider } from 'react-i18next';
import { ExperimentsProvider } from '@wix/wix-experiments-react';
import { WixStyleParamsProvider } from '@wix/editor-settings';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
//COMMON
import {
  ConsoleLogger,
  LogLevel,
} from '@wix/social-groups-common/dist/src/loggers';
import { isMobileByFormFactor } from '@wix/social-groups-common/dist/src/utils';
import { GroupNotFound } from '@wix/social-groups-common/dist/src/components/GroupNotFound';
import {
  CommonAppSettingsProvider,
  CommonTPAComponentsProvider,
} from '@wix/social-groups-common/dist/src/tpaProviders';
import { MemberInvitesControllerProps } from '@wix/social-groups-common/dist/src/controllers/member-invites/MemberInvitesControllerProps';
import { TranslationDataProps } from '@wix/social-groups-common/dist/src/controllers/translations/TranslationDataProps';
import {
  BiContextProvider,
  BIUserEntry,
  tryToCallBi,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context/bi-logger';
import { MemberInvitesProvider } from '@wix/social-groups-common/dist/src/context/member-invites';
import { UpdateProgress } from '@wix/social-groups-common/dist/src/components/ContentEditor/UpdateProgress';
import { ProfilePrivacyDialog } from '@wix/social-groups-common/dist/src/components/PrivacyDialog/ProfilePrivacyDialog';
//TODO: dynamic?
import { AppToastsProvider } from '@wix/social-groups-common/dist/src/components/AppToats';
import { WithAppToastsProps } from '@wix/social-groups-common/dist/src/types';
import { MembershipQuestionsModal } from '@wix/social-groups-common/dist/src/components/MembershipQuestionsModal';
//API
import {
  isProfilePublic,
  Anonymous,
} from '@wix/social-groups-api/dist/src/model/Member/Member';
import { canSeeGroup } from '@wix/social-groups-api/dist/src/model/Member/permissions';
import { isJoined } from '@wix/social-groups-api/dist/src/model/Member/MemberRole';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import i18n from '../../config/i18n';
import {
  FeedControllerProps,
  GroupControllerProps,
  Tab,
  MembersControllerProps,
  MainControllerProps,
  ErrorHandlerProps,
  ActivityControllerProps,
} from '../../controllers/types';
import { Group } from './Group';
import {
  AppData,
  AppDataContext,
  AppSettingsContext,
  GroupActionsProvider,
  GroupContextProvider,
  SiteMembersContext,
  WithAppSettingsProps,
} from './Context';
import { LoggersContext } from './Context/logger';
import { ActivityContext } from './Context/Activity';
import {
  NotificationSettings,
  NotificationSettingsContext,
} from './Context/NotificationSettings';
import { groupNotFound } from './Context/ErrorHandler/helpers';
import { ErrorHandlerContext } from './Context/ErrorHandler/ErrorHandlerContext';
import { LeaveGroupDialog } from './Dialogs/LeaveGroupDialog';
import { WithdrawJoinRequestDialog } from './Dialogs/WithdrawJoinRequest';
import { JoinDialog } from './Dialogs/JoinDialog';
import { DATA_HOOKS } from './dataHooks';
import { ProfileContext } from './Context/Profile/Profile';
import { hasAdminRole, hasSiteAdminRole } from '@wix/social-groups-api';

const enum OpenedDialog {
  WITHDRAW_JOIN_REQUEST = 'cancel join request',
  LEAVE_GROUP = 'leave group',
  JOIN_DIALOG = 'join dialog',
  PRIVACY_DIALOG = 'change profile privacy',
}

export interface GroupWidgetState {
  error: null | string;
  openDialog: OpenedDialog;
}

export interface GroupWidgetProps
  extends GroupControllerProps,
    FeedControllerProps,
    MembersControllerProps,
    MemberInvitesControllerProps,
    WithAppToastsProps,
    MainControllerProps,
    ActivityControllerProps,
    NotificationSettings,
    AppData,
    WithAppSettingsProps,
    WithBiLoggerProps,
    TranslationDataProps,
    ErrorHandlerProps {}

export class GroupWidget extends React.Component<
  GroupWidgetProps,
  GroupWidgetState
> {
  readonly state: GroupWidgetState = {
    error: null,
    openDialog: null,
  };

  constructor(props: GroupWidgetProps, context: any) {
    super(props, context);
    if (typeof window !== 'undefined') {
      ConsoleLogger.setModeFromLocation();
      window.scrollTo(0, 0);
    }
  }

  componentDidMount(): void {
    this.setupLocale(this.props.locale);
  }

  componentWillReceiveProps(
    nextProps: Readonly<GroupWidgetProps>,
    nextContext: any,
  ): void {
    if (nextProps.locale !== this.props.locale) {
      this.setupLocale(nextProps.locale);
    }
    if (nextProps.logLevel !== this.props.logLevel) {
      ConsoleLogger.mode = nextProps.logLevel;
    }
  }

  private setupLocale(lcl) {
    // TODO: make `moment/locale/${lcl}` work
    // + keep in mind that webpack's dynamic `import` does not work for SSR
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  static getDerivedStateFromError(error) {
    return {
      error,
    };
  }

  static defaultProps: Partial<GroupWidgetProps> = {
    isLoggedIn: false,
    promptLogin() {},
    actions: {} as any,
    activeTab: Tab.DISCUSSION,
    currentMember: Anonymous,
    experiments: {},
    group: GroupWrapper.createEmpty(),
    loading: false,
    locale: '',
    logLevel: LogLevel.NONE,
    memberInvitesLink: '',
    members: [],
    membersActions: {} as any,
    siteMembers: [],
    siteMembersMap: {},
    style: {} as any,
    uploadedRegistry: [],
    externalVideosMetadataRegistry: [],
    changeTab(): void {},
    commentFeedItem(): void {},
    createFeedItem(): void {},
    updateFeedItem(): void {},
    deleteFeedItem(): void {},
    pinFeedItem(): void {},
    unpinFeedItem(): void {},
    followFeedItem(): void {},
    unfollowFeedItem(): void {},
    updateProgress: UpdateProgress.STALE,
  };

  render() {
    const {
      appSettings,
      experiments,
      host,
      language,
      translation,
      biLogger,
    } = this.props;
    const { style, formFactor } = host;

    const isMobile = isMobileByFormFactor(formFactor);

    if (this.state.error !== null) {
      return (
        <>
          <h1>Ooops!</h1>
          <div>{this.state.error}</div>
        </>
      );
    }

    return (
      <div data-hook={DATA_HOOKS.root}>
        <I18nextProvider i18n={i18n({ language, translation })}>
          <ExperimentsProvider options={{ experiments }}>
            <TPAComponentsProvider value={{ mobile: isMobile }}>
              <CommonTPAComponentsProvider value={{ mobile: isMobile }}>
                <AppSettingsContext.Provider value={appSettings}>
                  <CommonAppSettingsProvider
                    value={{
                      design: {
                        buttonType:
                          appSettings &&
                          appSettings.design &&
                          appSettings.design.buttonType,
                      },
                    }}
                  >
                    <WixStyleParamsProvider {...style}>
                      <LoggersContext.Provider
                        value={{ logger: ConsoleLogger }}
                      >
                        <BiContextProvider value={biLogger}>
                          {this.renderGroup()}
                        </BiContextProvider>
                      </LoggersContext.Provider>
                    </WixStyleParamsProvider>
                  </CommonAppSettingsProvider>
                </AppSettingsContext.Provider>
              </CommonTPAComponentsProvider>
            </TPAComponentsProvider>
          </ExperimentsProvider>
        </I18nextProvider>
      </div>
    );
  }

  private renderGroup() {
    const { groupErrorCode, errorHandlers, host } = this.props;
    if (groupNotFound(groupErrorCode)) {
      const height = host.dimensions.height ? host.dimensions.height : 'auto';
      return (
        <div style={{ height }}>
          <GroupNotFound goToGroupList={errorHandlers.goToGroupList} />
        </div>
      );
    }
    const {
      actions,
      style,
      isRTL,
      cursor,
      contextToken,
      fetchMore,
      activeTab,
      activities,
      activityActions,
      apps,
      changeTab,
      commentFeedItem,
      createFeedItem,
      currentMember,
      pendingMembers,
      currentSiteMember,
      deleteFeedItem,
      feedItemId,
      feedItems,
      mediaItems,
      followFeedItem,
      followingMembers,
      group,
      rules,
      membershipQuestions,
      isFeedItemCreating,
      isLoggedIn,
      loading,
      loadMemberInvitesLink,
      feedLoading,
      memberInvitesLink,
      members,
      membersActions,
      membersQueryResponse,
      newMembers,
      notificationActions,
      notificationSettings,
      pinFeedItem,
      promptLogin,
      reactFeedItem,
      siteMembers,
      siteMembersMap,
      nonGroupMembersCount,
      toasts,
      unfollowFeedItem,
      unpinFeedItem,
      unreactFeedItem,
      updateFeedItem,
      updateProgress,
      uploadedRegistry,
      externalVideosMetadataRegistry,
      appDefinitionId,
      instance,
      location,
      membersUpdate,
      wixCodeApiParams,
      setWixCodeApiParams,
    } = this.props;

    return (
      <ErrorHandlerContext.Provider value={{ groupErrorCode, errorHandlers }}>
        <AppDataContext.Provider
          value={{
            appDefinitionId,
            instance,
            style,
            isRTL,
            host,
            location,
          }}
        >
          <GroupContextProvider
            value={{
              group,
              members,
              rules,
              membershipQuestions,
              currentMember,
              uploadedRegistry,
              externalVideosMetadataRegistry,
              feed: {
                feedLoading,
                contextToken,
                cursor,
                fetchMore,
                isFeedItemCreating,
                feedItems,
                mediaItems,
                deleteFeedItem,
                createFeedItem,
                updateFeedItem,
                commentFeedItem,
                pinFeedItem,
                unpinFeedItem,
                followFeedItem,
                unfollowFeedItem,
                reactFeedItem,
                unreactFeedItem,
              },
              promptLogin,
              isLoggedIn,
              updateProgress,
              apps,
              wixCodeApiParams,
              setWixCodeApiParams,
            }}
          >
            <GroupActionsProvider
              value={{
                ...actions,
                changeMembership: this.changeMembership,
                openJoinDialog: this.openJoinDialog,
              }}
            >
              <SiteMembersContext.Provider
                value={{
                  currentSiteMember,
                  followingMembers,
                  membersActions,
                  membersQueryResponse,
                  newMembers,
                  pendingMembers,
                  siteMembers,
                  siteMembersMap,
                  membersUpdate,
                  nonGroupMembersCount,
                }}
              >
                <AppToastsProvider value={toasts}>
                  <ActivityContext.Provider
                    value={{ activities, activityActions }}
                  >
                    <NotificationSettingsContext.Provider
                      value={{
                        notificationActions,
                        notificationSettings,
                      }}
                    >
                      <MemberInvitesProvider
                        value={{ memberInvitesLink, loadMemberInvitesLink }}
                      >
                        <ProfileContext.Provider
                          value={{
                            isProfilePrivate: this.isProfilePrivate(),
                            openProfileDialog: this.openProfileDialog,
                            isProfileUpdating: this.isProfileUpdating(),
                          }}
                        >
                          <Group
                            feedItemId={feedItemId}
                            activeTab={activeTab}
                            changeTab={changeTab}
                            canSeeGroup={canSeeGroup(group)}
                            ready={!!group.groupId}
                            apps={apps}
                            group={group}
                          />
                        </ProfileContext.Provider>
                        {this.renderWithdrawJoinRequestDialog()}
                        {this.renderLeaveDialog()}
                        {this.renderJoinDialog()}
                        {this.renderPrivacyDialog()}
                        {this.renderMembershipQuestionsStep()}
                      </MemberInvitesProvider>
                    </NotificationSettingsContext.Provider>
                  </ActivityContext.Provider>
                </AppToastsProvider>
              </SiteMembersContext.Provider>
            </GroupActionsProvider>
          </GroupContextProvider>
        </AppDataContext.Provider>
      </ErrorHandlerContext.Provider>
    );
  }

  private isProfileUpdating() {
    const { membersUpdate, currentSiteMember } = this.props;
    return (
      membersUpdate &&
      currentSiteMember &&
      membersUpdate.includes(currentSiteMember.id)
    );
  }

  private renderLeaveDialog() {
    const { group } = this.props;
    const groupWrapper = new GroupWrapper(group);

    return (
      <LeaveGroupDialog
        isOpen={this.isOpenDialog(OpenedDialog.LEAVE_GROUP)}
        onRequestClose={this.closeDialog}
        groupId={group.groupId}
        groupTitle={groupWrapper.getTitle()}
        onLeaveGroup={this.handleLeaveGroupConfirm}
      />
    );
  }

  private renderWithdrawJoinRequestDialog() {
    return (
      <WithdrawJoinRequestDialog
        isOpen={this.isOpenDialog(OpenedDialog.WITHDRAW_JOIN_REQUEST)}
        onRequestClose={this.closeDialog}
        onWithdrawJoinRequest={this.handleWithdrawJoinRequest}
      />
    );
  }

  private renderJoinDialog() {
    return (
      <JoinDialog
        isOpen={this.isOpenDialog(OpenedDialog.JOIN_DIALOG)}
        onRequestClose={this.closeDialog}
      />
    );
  }

  private renderMembershipQuestionsStep() {
    const {
      membershipQuestions,
      group,
      showMembershipQuestionsStep,
      actions,
      host,
    } = this.props;
    const groupWrapper = new GroupWrapper(group);
    return (
      <MembershipQuestionsModal
        mobile={isMobileByFormFactor(host.formFactor)}
        isOpen={showMembershipQuestionsStep}
        onRequestClose={() => actions.closeMembershipQuestionStep()}
        questions={membershipQuestions}
        groupName={groupWrapper.getTitle()}
        groupId={group.groupId}
        onSubmit={answers => this.changeMembership(null, answers)}
      />
    );
  }

  private renderPrivacyDialog() {
    return (
      this.isProfilePrivate() && (
        <ProfilePrivacyDialog
          onRequestClose={this.closeDialog}
          onChangeProfile={this.makeProfilePublic}
          isOpen={this.isOpenDialog(OpenedDialog.PRIVACY_DIALOG)}
        />
      )
    );
  }

  private readonly changeMembership = (
    biOrigin: string,
    membershipQuestionsAnswers?: ApiTypes.v1.MembershipQuestionAnswer[],
  ) => {
    const { group } = this.props;

    switch (group.relationship) {
      case ApiTypes.v1.RelationshipWithGroup.REJECTED_MEMBERSHIP:
      case ApiTypes.v1.RelationshipWithGroup.NONE:
        return this.handleJoinGroup(biOrigin, membershipQuestionsAnswers);
      case ApiTypes.v1.RelationshipWithGroup.JOINED:
        return this.handleLeaveGroupClick(biOrigin);
      case ApiTypes.v1.RelationshipWithGroup.PENDING_APPROVAL:
        return this.openWithdrawJoinRequestDialog();
      default:
        console.warn('Unknown group relationship');
    }
  };

  private isProfilePrivate(): boolean {
    const { currentSiteMember } = this.props;
    return currentSiteMember && !isProfilePublic(currentSiteMember);
  }

  private readonly makeProfilePublic = () => {
    try {
      this.props.membersActions.makeProfilePublic(
        this.props.currentSiteMember.id,
      );
    } catch (e) {
      console.log('Failed to make profile public');
    }
  };
  private readonly openProfileDialog = () =>
    this.openDialog(OpenedDialog.PRIVACY_DIALOG);
  private readonly openJoinDialog = () => {
    if (this.isProfilePrivate()) {
      return this.openProfileDialog();
    }
    return this.openDialog(OpenedDialog.JOIN_DIALOG);
  };
  private readonly openLeaveDialog = () =>
    this.openDialog(OpenedDialog.LEAVE_GROUP);
  private readonly openWithdrawJoinRequestDialog = () =>
    this.openDialog(OpenedDialog.WITHDRAW_JOIN_REQUEST);
  private readonly closeDialog = () => this.setState({ openDialog: null });
  private readonly openDialog = dialog => this.setState({ openDialog: dialog });
  private isOpenDialog(dialog: OpenedDialog): boolean {
    return this.state.openDialog === dialog;
  }

  private readonly handleLeaveGroupConfirm = () => {
    this.props.actions.leaveGroup();
    this.closeDialog();
  };

  private readonly handleWithdrawJoinRequest = () => {
    this.props.actions.withdrawJoinRequest();
    this.closeDialog();
  };

  private readonly handleJoinGroup = (
    biOrigin: string,
    membershipQuestionsAnswers?: ApiTypes.v1.MembershipQuestionAnswer[],
  ) => {
    const { group, biLogger, actions, isLoggedIn } = this.props;
    if (!isJoined(group)) {
      tryToCallBi(async () => {
        await biLogger.groupsRequestJoinAGroup({
          approvalStatus: group.approvalStatus,
          groupId: group.groupId,
          origin: biOrigin,
          type: group.settings.privacyLevel,
        } as any);
      });
    }
    actions.joinGroup(membershipQuestionsAnswers);
    this.closeDialog();
  };

  private readonly handleLeaveGroupClick = (biOrigin: string) => {
    const { group, biLogger } = this.props;
    tryToCallBi(async () => {
      await biLogger.groupActionClick({
        action: 'leave',
        group_id: group.groupId,
        origin: biOrigin,
        userEntry: BIUserEntry.SITE,
      });
    });
    this.openLeaveDialog();
  };
}
