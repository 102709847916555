import * as React from 'react';
import { PagesContext } from './PagesContext';

class PagesProps {
  activePage: string;
}

export const Pages: React.FunctionComponent<PagesProps> = ({
  children,
  activePage,
}) => {
  return (
    <PagesContext.Provider children={children || null} value={{ activePage }} />
  );
};
