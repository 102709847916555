import {
  FeedItemActivityType,
  IFeedItemActivity,
} from '../../../../controllers/feed/store/types';
import { WixStatics } from '@wix/social-groups-api/dist/src/services/wixStatics/WixStatics';
import { RawDraftContentState } from '@wix/social-groups-common/dist/src/components/ContentEditor/types';

export async function getActivityBody(
  activity: IFeedItemActivity,
): Promise<RawDraftContentState<any>> {
  switch (activity.activityType) {
    case FeedItemActivityType.GROUP_COVER_CHANGED:
      return getGroupCoverChangedBody(activity.data.src);
    case FeedItemActivityType.ABOUT_GROUP_CHANGED:
      return JSON.parse(activity.data.content);
    default:
      return null;
  }
}

// Temporary solution
// TODO: RCE doesn't provide contentState builder, but they are working on it
// https://app.asana.com/0/1161782908860633/1168185103428194/f
// https://docs.google.com/document/d/1aQ9jIQ5PBb5O5BxSxLgShebw75lU7NleK8M4Is_17F4/edit
async function getGroupCoverChangedBody(
  src: string,
): Promise<RawDraftContentState<any>> {
  // for getting imageSize need to build fileUrl without fit params
  const { fileName } = WixStatics.getWixmpFileNameFromImageURL(src);
  const imageSize = await getImageSize(WixStatics.imageUrl(fileName));
  return {
    blocks: [
      {
        key: '7c2th',
        text: ' ',
        type: 'atomic',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [
          {
            offset: 0,
            length: 1,
            key: 0,
          },
        ],
        data: {},
      },
    ],
    entityMap: {
      '0': {
        type: 'wix-draft-plugin-image',
        mutability: 'IMMUTABLE',
        data: {
          config: {
            alignment: 'center',
            size: 'fullWidth',
            showTitle: true,
            showDescription: true,
          },
          src: {
            id: 'a85171218ed414df18aab4578f9d5205',
            original_file_name: fileName,
            file_name: fileName,
            ...imageSize,
          },
        },
      },
    },
    VERSION: '7.2.0',
  } as RawDraftContentState<any>;
}

function getImageSize(src): Promise<{ width: number; height: number }> {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = function() {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.onabort = img.onerror = () =>
      resolve({
        width: 100,
        height: 100,
      });
    img.src = src;
  });
}
