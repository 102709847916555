import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { debounce, partition } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import * as Url from 'url-parse';

//COMMON
import { Spinner } from '@wix/social-groups-common/dist/src/components/Spinner';
import { ErrorBoundary } from '@wix/social-groups-common/dist/src/components/ErrorBoundary';
import { ShareModal } from '@wix/social-groups-common/dist/src/components/ShareModal';
import {
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context/bi-logger';

//API
import {
  ApiTypes,
  ReactionsApiTypes,
} from '@wix/social-groups-api/dist/src/types';
import { isOwner } from '@wix/social-groups-api/dist/src/model/Member/MemberRole';

import { IFeedItem } from '../../../../controllers/feed/types';
import { FeedItem } from '../FeedItem';
import { FirstPost } from '../FirstPost';
import {
  Loggers,
  withAppData,
  WithAppDataProps,
  WithGroup,
  WithGroupProps,
  withLogger,
  withSiteMembers,
} from '../../Context';
import { PinnedPost } from '../PinnedPost';
import { getPostAuthor } from '../../../../controllers/feed/helpers';
import { WithSiteMembers } from '../../Context/withSiteMembers';
import { SomethingWentWrong } from '../SomethingWentWrong';

interface FeedListProps {
  hasMore: boolean;
  feedItems: IFeedItem[];
  currentMember: ApiTypes.v1.GroupMemberResponse;
  fetchMore(): void;
  contextToken: string;
}

export interface FeedProps extends FeedListProps {
  onCreatePostClick(): void;
  onDeletePostClick(feedItemId: string): void;
  onPinPostClick(feedItemId: string): void;
  onUnpinPostClick(feedItemId: string): void;
  onFollowPostClick(feedItemId: string): void;
  onUnfollowPostClick(feedItemId: string): void;
  onUpdatePostClick(feedItemId: string, entity): void;
  react(feedItemId: string, reaction: ReactionsApiTypes.Reaction): void;
  unreact(feedItemId: string, reactionCode: string): void;
}

interface FeedItemsProps extends FeedListProps, FeedProps {
  siteMembersMap: { [id: string]: ApiTypes.v1.GroupMemberResponse };

  onShare(itemId: string);
}

const FeedList = ({
  currentMember,
  feedItems,
  siteMembersMap,
  onDeletePostClick,
  onPinPostClick,
  onUnpinPostClick,
  onFollowPostClick,
  onUnfollowPostClick,
  onUpdatePostClick,
  react,
  unreact,
  onShare,
  contextToken,
}) => {
  return (
    <div data-hook="feed-list">
      {feedItems.map(feedItem => {
        const postAuthor = getPostAuthor(feedItem, siteMembersMap);
        const { feedItemId } = feedItem;
        return (
          <FeedItem
            key={feedItemId}
            feedItem={feedItem}
            postAuthor={postAuthor}
            onDeletePostClick={onDeletePostClick}
            onPinPostClick={onPinPostClick}
            onUnpinPostClick={onUnpinPostClick}
            onFollowPostClick={onFollowPostClick}
            onUnfollowPostClick={onUnfollowPostClick}
            onUpdatePostClick={onUpdatePostClick}
            siteMembersMap={siteMembersMap}
            currentMember={currentMember}
            react={react}
            unreact={unreact}
            onShare={() => onShare(feedItemId)}
            contextToken={contextToken}
          />
        );
      })}
    </div>
  );
};

interface FeedState {
  shareItemId: string;
}

type Props = FeedProps &
  WithSiteMembers &
  Loggers &
  WithGroupProps &
  WithAppDataProps &
  WithBiLoggerProps &
  InjectedTranslateProps;

export class FeedComponent extends React.Component<Props> {
  state: FeedState = {
    shareItemId: null,
  };

  fetchMore = debounce(this.fetchMorePure, 200).bind(this);

  fetchMorePure() {
    this.props.fetchMore();
  }

  render() {
    const {
      t,
      group,
      currentMember,
      hasMore,
      feedItems,
      onCreatePostClick,
      onDeletePostClick,
      onPinPostClick,
      onUnpinPostClick,
      onFollowPostClick,
      onUnfollowPostClick,
      onUpdatePostClick,
      siteMembersMap,
      logger,
      react,
      unreact,
      contextToken,
    } = this.props;
    const [pinnedFeedItems, notPinnedFeedItems] = partition(
      feedItems,
      this.isPinnedPost,
    );
    const pinnedPost = pinnedFeedItems[0];

    const postActions = {
      onDeletePostClick,
      onPinPostClick,
      onUnpinPostClick,
      onFollowPostClick,
      onUnfollowPostClick,
      onUpdatePostClick,
      react,
      unreact,
    };
    return (
      <>
        {!feedItems.length ? (
          <FirstPost onClick={onCreatePostClick} />
        ) : (
          <ErrorBoundary
            loggers={[logger]}
            errorComponent={
              <SomethingWentWrong isOwner={isOwner(group, currentMember)} />
            }
          >
            {pinnedPost ? (
              <PinnedPost
                currentMember={currentMember}
                siteMembersMap={siteMembersMap}
                feedItem={pinnedPost}
                postAuthor={getPostAuthor(pinnedPost, siteMembersMap)}
                onShare={() => this.onShare(pinnedPost.feedItemId)}
                contextToken={contextToken}
                {...postActions}
              />
            ) : null}

            <InfiniteScroll
              pageStart={0}
              hasMore={hasMore}
              initialLoad={false}
              loadMore={this.fetchMore}
              loader={
                <Spinner
                  key={'Spinner'}
                  offset="M"
                  label={t('groups-web.loading')}
                />
              }
            >
              <FeedList
                key={'FeedList'}
                currentMember={currentMember}
                siteMembersMap={siteMembersMap}
                feedItems={notPinnedFeedItems}
                onShare={this.onShare}
                contextToken={contextToken}
                {...postActions}
              />
            </InfiniteScroll>
          </ErrorBoundary>
        )}
        <ShareModal
          biLogger={this.props.biLogger}
          isOpen={!!this.state.shareItemId}
          onRequestClose={this.closeShareDialog}
          title={t('groups-web.discussion.feed.post-actions.share.dialog')}
          shareUrl={this.getSharePostUrl(this.state.shareItemId)}
        />
      </>
    );
  }

  private readonly onShare = (shareItemId: string) =>
    this.setState({ shareItemId });

  private readonly closeShareDialog = () =>
    this.setState({ shareItemId: null });

  private getSharePostUrl(itemId) {
    const shareUrl = this.props.location && this.props.location.url;
    const { pathname, origin } = new Url(shareUrl);
    return `${origin}${pathname}/${itemId}`;
  }

  private isPinnedPost(feedItem) {
    return feedItem.pin;
  }
}

export const Feed = withLogger(
  withBiLogger(
    withSiteMembers(WithGroup(withAppData(translate()(FeedComponent)))),
  ),
) as React.ComponentType<FeedProps>;
