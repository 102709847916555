import * as React from 'react';
import { IPagesContext, PagesContext } from './PagesContext';

interface PageProps {
  name: string;
  children?: React.ReactNode;
}

export const Page: React.FunctionComponent<PageProps> = ({
  children,
  name,
}: PageProps) => {
  return (
    <PagesContext.Consumer>
      {({ activePage }: IPagesContext) => {
        return activePage === name ? children : null;
      }}
    </PagesContext.Consumer>
  );
};
