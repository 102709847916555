import * as React from 'react';
import { translate, InjectedTranslateProps } from 'react-i18next';

import { GroupRule } from '@wix/social-groups-api';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  Spinner,
  Box,
  Header,
} from '@wix/social-groups-common/dist/src/components';

import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';

import { WithGroupActions, WithGroupActionProps } from '../../Context';
import styles from './GroupRules.st.css';
import aboutStyles from '../About.st.css';

interface GroupRulesProps {
  rules: GroupRule[];
}

class GroupRulesComponent extends React.Component<
  GroupRulesProps & InjectedTranslateProps & WithGroupActionProps
> {
  componentDidMount() {
    this.props.fetchGroupRules();
  }

  render() {
    const { t, rules } = this.props;

    if (!rules) {
      return <Spinner offset="M" label={t('groups-web.loading')} />;
    }

    if (!rules.length) {
      return null;
    }

    return (
      <Box className={styles.root}>
        <div className={styles.cardHeader}>
          <Header className={aboutStyles.header}>
            {t('groups-web.about.rules.title')}
          </Header>
        </div>
        <ul>
          {rules.map(rule => (
            <li key={rule.id} {...styles(styles.rule)}>
              <Text
                tagName="p"
                typography={TYPOGRAPHY.largeTitle}
                {...styles(styles.title)}
              >
                {rule.title}
              </Text>
              <Text
                tagName="p"
                typography={TYPOGRAPHY.listText}
                {...styles(styles.description)}
              >
                {rule.description}
              </Text>
            </li>
          ))}
        </ul>
      </Box>
    );
  }
}

export const GroupRules = compose(
  translate(),
  WithGroupActions,
)(GroupRulesComponent);
