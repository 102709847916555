import * as React from 'react';
import {
  MembershipChangeAction,
  MembershipAction,
  MembershipQuestionsAction,
} from './MembershipAction';
import { GroupUpdate } from './GroupUpdate';
import { GroupDelete } from './GroupDelete';
import { UploadAction } from '@wix/social-groups-common/dist/src/components/ContentEditor/UploadFile';
import { ExternalVideoMetadataAction } from '@wix/social-groups-common/dist/src/components/ContentEditor/ExternalVideoMetadata';
import { InviteMembersProps } from './InviteMembersProps';
import { GroupApps } from './GroupApps';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

export interface IGroupActions
  extends UploadAction,
    ExternalVideoMetadataAction,
    MembershipAction,
    MembershipQuestionsAction,
    InviteMembersProps,
    GroupUpdate,
    GroupApps,
    GroupDelete {
  changeTab(tab: string);
  goToGroupList();
  fetchGroupRules();
}

export const aDefaultGroupActions: GroupActionsContextType = {
  deleteGroup() {},
  setEditMode() {},
  updateGroup() {},
  changeMembership(biOrigin: string) {},
  openJoinDialog() {},
  uploadFiles() {},
  getExternalVideoMetadata() {},
  changeTab() {},
  goToGroupList() {},
  inviteMembersByEmail(emails: string[]) {},
  configureApps() {},
  fetchGroupRules() {},
  closeMembershipQuestionStep() {},
};

export type GroupActionsContextType = Omit<
  IGroupActions,
  keyof MembershipAction
> &
  MembershipChangeAction;

export const GroupActionsContext = React.createContext<GroupActionsContextType>(
  aDefaultGroupActions,
);

GroupActionsContext.displayName = 'GroupActionsContext';

export const GroupActionsProvider = GroupActionsContext.Provider;
export const GroupActionsConsumer = GroupActionsContext.Consumer;

export interface WithGroupActionProps extends IGroupActions {}

export const WithGroupActions = <P extends WithGroupActionProps>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<Omit<P, keyof WithGroupActionProps>> => props => (
  <GroupActionsConsumer>
    {contextValue => <WrappedComponent {...props} {...(contextValue as any)} />}
  </GroupActionsConsumer>
);
