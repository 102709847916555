import * as React from 'react';

export const ImagePluginIcon: React.FC<React.SVGAttributes<
  SVGSVGElement
>> = props => (
  <svg viewBox="0 0 19 19" {...props}>
    <g fillRule="evenodd">
      <path d="M2 6a1 1 0 0 1 1-1h2.75l.668-1.424A1 1 0 0 1 7.323 3h4.354a1 1 0 0 1 .905.576L13.25 5H16a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6zm1 0v8h13V6h-3.5l-1.018-2H7.518L6.5 6H3zm6.5 6a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0-1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"></path>
    </g>
  </svg>
);
