import * as React from 'react';
import { Popover } from 'wix-ui-core/popover';
import { IconButton, Skins } from 'wix-ui-tpa/IconButton';
import styles from './ThreeDots.st.css';
import { EllipsesIcon } from '../icons/EllipsesIcon';
import { ActionsMenuLayout } from 'wix-ui-tpa/ActionsMenuLayout';
import { ActionsMenuLayoutItemProps } from 'wix-ui-tpa/dist/src/components/ActionsMenuLayout/Item/ActionsMenuLayoutItem';

export type ThreeDotsActions = ActionsMenuLayoutItemProps;
interface ThreeDotsProps {
  isOpen: boolean;
  onClose();
  onOpen(e?);
  items: ThreeDotsActions[];
  iconClassName?: string;
  className?: string;
}

export const ThreeDots: React.FunctionComponent<ThreeDotsProps> = (
  props: ThreeDotsProps,
) => {
  const { isOpen, onClose, items, onOpen, iconClassName, ...restProps } = props;

  return (
    <Popover
      appendTo="parent"
      shown={isOpen}
      placement="bottom-end"
      onClickOutside={onClose}
      {...styles('root', {}, restProps)}
    >
      <Popover.Element>
        <IconButton
          onClick={onOpen}
          skin={Skins.Full}
          {...styles(styles.icon, {}, { className: iconClassName })}
          icon={<EllipsesIcon width={24} height={24} />}
        />
      </Popover.Element>
      <Popover.Content>
        <ActionsMenuLayout {...styles(styles.actionsMenu, {})}>
          {items.map(p => (
            <ActionsMenuLayout.Item key={p.content} {...p} />
          ))}
        </ActionsMenuLayout>
      </Popover.Content>
    </Popover>
  );
};
