import memoize from 'lodash/memoize';
import * as i18next from 'i18next';
import { GROUP_PAGE } from './constants';

export const i18nInstance = i18next.createInstance();

export interface I18nConfig {
  language: string;
  translation?: object;
}

export default memoize(function i18n(params: I18nConfig) {
  const { language, translation } = params;
  const options: any = {
    // Initial language
    lng: language,

    // Fallback language
    fallbackLng: 'en',

    // Don't use a key separator (no support for nested translation objects)
    keySeparator: false,

    // Wait for translation data to be available before rendering a component
    react: {
      wait: true,
    },
  };

  if (translation) {
    options.resources = {
      [language]: {
        translation,
      },
    };
  } else {
    const fileName = GROUP_PAGE;
    i18nInstance.use({
      type: 'backend',
      read: async (lang, namespace, callback) =>
        import(`../assets/locales/${fileName}_${lang}.json`)
          .then(translationForLang => callback(null, translationForLang))
          .catch(error => callback(error)),
    });
  }

  return i18nInstance.init(options);
});
