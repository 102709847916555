import * as React from 'react';

export const AddMembersIcon = props => (
  <svg viewBox="0 0 16 12" {...props}>
    <defs>
      <path id="a" d="M7.167 9.367v2h2v1h-2v2h-1v-2h-2v-1h2v-2h1z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-4 -6)">
        <path d="M0 0h24v24H0z" />
        <use fill="currentColor" xlinkHref="#a" />
        <circle cx="14.667" cy="9.2" r="3.2" fill="currentColor" />
        <path
          fill="currentColor"
          d="M16.5 13.467a3.5 3.5 0 0 1 3.5 3.5v.466c0 .101-.004.201-.013.3H9.346a3.546 3.546 0 0 1-.013-.3v-.466a3.5 3.5 0 0 1 3.5-3.5H16.5z"
        />
      </g>
    </g>
  </svg>
);
