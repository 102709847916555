import * as React from 'react';
import {
  TPAModal,
  TPAModalProps,
} from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { Text } from 'wix-ui-tpa/Text';
import { Grid } from 'wix-ui-tpa/Grid';
import classname from 'classnames';

import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../Context/withTpaComponentsConfig';

import styles from './MembersModal.st.css';

type IModal<P> = React.FC<P> & {
  Header: React.FC;
  Content: React.FC<{ className?: string }>;
};

type MembersModalProps = TPAModalProps & WithTpaComponentsConfigProps;

const MembersModalComponent: React.FC<MembersModalProps> = props => {
  const { children, isOpen, mobile, onRequestClose } = props;

  return (
    <TPAModal
      isOpen={isOpen}
      inFullScreen={mobile}
      onRequestClose={onRequestClose}
      {...styles('root', { mobile }, props)}
    >
      {children}
    </TPAModal>
  );
};

export const MembersModal = compose(withTpaComponentsConfig)(
  MembersModalComponent,
) as IModal<TPAModalProps>;

MembersModal.Header = ({ children }) => (
  <Text className={styles.title}>{children}</Text>
);
MembersModal.Content = ({ children, className }) => (
  <div className={classname(styles.content, className)}>
    <Grid maxColumns={1} width={220} minColumnWidth={220} rowGap={0}>
      {children as any}
    </Grid>
  </div>
);
