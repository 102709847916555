import * as React from 'react';
import { Dialog } from '@wix/social-groups-common/dist/src/components/Dialog';
import { ModalProps } from '@wix/social-groups-common/dist/src/components/Modal';
import { Button } from '@wix/social-groups-common/dist/src/components/Button';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { DATA_HOOKS } from './dataHooks';

export interface WithdrawJoinRequestDialogProps extends ModalProps {
  onWithdrawJoinRequest();
}

type Props = WithdrawJoinRequestDialogProps & InjectedTranslateProps;
const WithdrawJoinRequestDialogComponent: React.FC<Props> = props => {
  const { t, onWithdrawJoinRequest, ...rest } = props;
  return (
    <Dialog
      title={t('groups-web.viewer.cancel-request-modal.title')}
      caption={t('groups-web.viewer.cancel-request-modal.body')}
      buttons={
        <>
          <Button
            onClick={onWithdrawJoinRequest}
            priority={PRIORITY.primary}
            data-hook={DATA_HOOKS.withdrawJoinRequestConfirmationButton}
          >
            {t('groups-web.viewer.cancel-request-modal.cta')}
          </Button>
        </>
      }
      {...rest}
    />
  );
};

const enhance = compose(translate());

export const WithdrawJoinRequestDialog = enhance(
  WithdrawJoinRequestDialogComponent,
) as React.ComponentType<WithdrawJoinRequestDialogProps>;
