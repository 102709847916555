import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
//WIX_UI_TPA
import { Avatar, AvatarSize } from 'wix-ui-tpa/Avatar';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';
//API
import { memberWrapper } from '@wix/social-groups-api/dist/src/model/Member/Member';
import { SocialApiTypes } from '@wix/social-groups-api/dist/src/types';
//COMMON
import {
  Button,
  Loader,
  Modal,
  ModalSkin,
  Spinner,
  withTPAConfig,
} from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { ModalParentNodeRefProvider } from '@wix/social-groups-common/dist/src/components/Modal/ModalParentNodeRefContext';
import { RawDraftContentState } from '@wix/social-groups-common/dist/src/components/ContentEditor/types';
import { UpdateProgress } from '@wix/social-groups-common/dist/src/components/ContentEditor/UpdateProgress';
import { WithGroup, WithGroupProps } from '../../Context/GroupContext';
import {
  IEditorEvents,
  IEditorEventsContext,
} from '../../PostContentEditor/IEditorEvents';

const PostEditor = React.lazy(() =>
  import(
    /* webpackChunkName: "asyncEditors" */ '../../../ContentEditor/asyncEditors'
  )
    .then(module => ({ default: module.default.PostEditor }))
    .catch(e => {
      return { default: () => null };
    }),
);
//STYLES
import styles from './NewPostModal.st.css';

interface NewPostModalProps {
  isOpen: boolean;
  isPostPublishing: boolean;
  initialContentState?: RawDraftContentState<any>;

  onSubmit(
    post: RawDraftContentState<any>,
    attachments?: SocialApiTypes.Resource[],
  ): void;

  onVisibilityChange(isOpen: boolean): void;

  onSaveDraft?(post: RawDraftContentState<any>);
}

type Props = NewPostModalProps &
  InjectedTranslateProps &
  TPAComponentsConfig &
  WithGroupProps;

interface State {
  post: RawDraftContentState<any>;
}

class NewPostModalComponent extends React.Component<Props, State> {
  static defaultProps = {
    initialContentState: null,
    onSaveDraft() {},
    currentMember: {
      name: {},
    },
  };
  state: State = {
    post: null,
  };
  $parent = React.createRef<HTMLDivElement>();
  private editorEvents: IEditorEventsContext;
  private editorEventTypes: IEditorEvents;

  handleClose = () => {
    this.props.onSaveDraft(this.state.post);
    this.props.onVisibilityChange(false);
  };

  setPost = (post?: RawDraftContentState<any>) => {
    this.setState({ post });
  };

  handleSubmit = async () => {
    try {
      await this.editorEvents.dispatch(this.editorEventTypes.PUBLISH);
    } catch (e) {
      console.log('PostEditorComponent Error in EditorEvents');
    } finally {
      this.props.onSubmit(this.state.post);
      this.props.onSaveDraft(null);
      this.setPost(null);
    }
  };
  setEditorEvents = (
    editorEvents: IEditorEventsContext,
    events: IEditorEvents,
  ) => {
    this.editorEvents = editorEvents;
    this.editorEventTypes = events;
  };

  render() {
    const { isOpen, mobile } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleClose}
        withCloseButton={!mobile}
        skin={ModalSkin.NEW_POST}
      >
        <div
          {...styles('root', { mobile }, this.props)}
          data-hook="new-post-modal"
        >
          {mobile ? this.renderMobileLayout() : this.renderDesktopLayout()}
        </div>
      </Modal>
    );
  }

  isPostButtonDisabled = () => {
    return !this.state.post || this.isLoading();
  };

  renderDesktopLayout() {
    const { t, currentMember, isPostPublishing } = this.props;
    const { name, imageUrl } = memberWrapper(currentMember);

    return (
      <>
        <div className={styles.profileInfo}>
          <Avatar
            src={imageUrl}
            size={AvatarSize.large}
            {...styles('avatar')}
          />
          <Text className={styles.currentMemberName}>
            {name.nick || t('groups-web.member.anonymous')}
          </Text>
        </div>
        {this.renderPostEditor()}
        <div className={styles.actionButtons}>
          <Button className={styles.cancelButton} onClick={this.handleClose}>
            {t('groups-web.discussion.new-post.cancel')}
          </Button>
          <Button
            priority={PRIORITY.primary}
            disabled={this.isPostButtonDisabled()}
            onClick={this.handleSubmit}
            prefixIcon={this.isLoading() && <Loader />}
          >
            {isPostPublishing
              ? t('groups-web.discussion.new-post.publishing')
              : t('groups-web.discussion.new-post.publish')}
          </Button>
        </div>
      </>
    );
  }

  renderMobileLayout() {
    const { t } = this.props;
    return (
      <>
        <div className={styles.mobileHeader}>
          <TextButton
            onClick={this.handleClose}
            priority={TEXT_BUTTON_PRIORITY.secondary}
          >
            {t('groups-web.discussion.new-post.mobile.back')}
          </TextButton>
          <Text
            typography={TYPOGRAPHY.smallTitle}
            className={styles.mobileTitle}
          >
            {t('groups-web.discussion.new-post.mobile.title')}
          </Text>
          <TextButton
            onClick={this.handleSubmit}
            priority={TEXT_BUTTON_PRIORITY.primary}
            disabled={this.isPostButtonDisabled()}
          >
            {t('groups-web.discussion.new-post.mobile.post')}
          </TextButton>
        </div>
        {this.renderPostEditor()}
      </>
    );
  }

  private isLoading() {
    const { isPostPublishing, updateProgress } = this.props;
    return isPostPublishing || updateProgress !== UpdateProgress.STALE;
  }

  private renderPostEditor() {
    const { mobile, initialContentState, isPostPublishing } = this.props;
    return (
      <div
        ref={this.$parent}
        className={styles.rceWrapper}
        data-hook="new-post-modal"
      >
        <ModalParentNodeRefProvider value={this.$parent.current}>
          <React.Suspense fallback={<Spinner />}>
            <PostEditor
              mobile={mobile}
              initialContentState={initialContentState}
              isPostPublishing={isPostPublishing}
              onChange={this.setPost}
              onEditorEvents={this.setEditorEvents}
            />
          </React.Suspense>
        </ModalParentNodeRefProvider>
      </div>
    );
  }
}

const enhance = compose(translate(), withTPAConfig, WithGroup);

export const NewPostModal = enhance(NewPostModalComponent);
