import * as React from 'react';
export interface ActivityActions {
  getActivity(groupId: string);
}

export interface Activity {
  activities: {
    newPosts: number;
  };
  activityActions: ActivityActions;
}

export const ActivityContext = React.createContext<Activity>({
  activities: undefined,
  activityActions: undefined,
});
