import {
  FeedApiTypes,
  CommentsApiTypes,
  ReactionsApiTypes,
} from '@wix/social-groups-api/dist/src/types';
import { IProGalleryItem } from '@wix/social-groups-common/dist/src/components/ContentEditor/plugins/pro-gallery/ProGalleryItem';

import { IFeedItem } from './store/types';

export * from './store/types';

export interface FeedControllerProps {
  cursor: string;
  feedLoading: boolean;
  feedItems: IFeedItem[];
  mediaItems: IProGalleryItem[];
  isFeedItemCreating: boolean;
  contextToken: string;
  fetchMore(): void;
  createFeedItem(entity: FeedApiTypes.FeedItemEntity): void;
  updateFeedItem(feedItemId: string, entity: FeedApiTypes.FeedItemEntity): void;
  deleteFeedItem(feedItemId: string): void;
  commentFeedItem(
    feedItemId: string,
    entity: CommentsApiTypes.CommentEntity,
  ): void;
  pinFeedItem(feedItemId: string): void;
  unpinFeedItem(feedItemId: string): void;
  followFeedItem(feedItemId: string): void;
  unfollowFeedItem(feedItemId: string): void;
  reactFeedItem(feedItemId: string, reaction: ReactionsApiTypes.Reaction): void;
  unreactFeedItem(feedItemId: string, reactionCode: string): void;
}
