import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { TextButton, TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  MembersActions,
  SiteMembers,
  WithGroup,
  WithGroupActionProps,
  WithGroupActions,
  withSiteMembers,
} from '../../Context';
import { getPostAuthor } from '../../../../controllers/feed/helpers';
import { FeedItem, FeedItemProps } from '../FeedItem';
import { ArrowBackIcon } from '../../../icons/ArrowBackIcon';

import styles from './PostById.st.css';
import { Tab } from '../../../../controllers/types';
import { EmptyState } from '../../EmptyState';

export interface PostByIdProps extends Omit<FeedItemProps, 'postAuthor'> {}

type Props = PostByIdProps &
  InjectedTranslateProps &
  MembersActions &
  SiteMembers &
  WithGroupActionProps;
const PostByIdComponent: React.FC<Props> = props => {
  const { t, changeTab } = props;
  const goToDiscussion = () => changeTab(Tab.DISCUSSION);
  return (
    <div {...styles('root', {}, props)}>
      <TextButton
        prefixIcon={<ArrowBackIcon width="24px" height="24px" />}
        onClick={goToDiscussion}
        className={styles.backButton}
        priority={TEXT_BUTTON_PRIORITY.secondary}
      >
        {t('groups-web.discussion.post-not-found.back')}
      </TextButton>
      {props.feedItem ? (
        <FeedItem
          {...props}
          postAuthor={getPostAuthor(props.feedItem, props.siteMembersMap)}
        />
      ) : (
        <EmptyState
          className={styles.emptyState}
          title={t('groups-web.discussion.post-not-found.title')}
          content={t('groups-web.discussion.post-not-found.subtitle')}
          button={{
            label: t('groups-web.discussion.post-not-found.back-to-discussion'),
            onClick: goToDiscussion,
          }}
        />
      )}
    </div>
  );
};

const enhance = compose(
  translate(),
  withSiteMembers,
  WithGroup,
  WithGroupActions,
);

export const PostById = enhance(PostByIdComponent);
