import * as React from 'react';
import { IProfileContext, ProfileContext } from './Profile';

export interface WithProfileProps extends IProfileContext {}

export const withProfile = <P extends WithProfileProps>(
  WrappedComponent: React.ComponentType<P>,
) => props => {
  return (
    <ProfileContext.Consumer>
      {(context: IProfileContext) => (
        <WrappedComponent {...props} {...context} />
      )}
    </ProfileContext.Consumer>
  );
};
