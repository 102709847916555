import * as React from 'react';
import { Activity, ActivityContext } from './Activity';

export interface WithActivityProps extends Activity {}

export const withActivity = <P extends WithActivityProps>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof WithActivityProps>> => props => {
  return (
    <ActivityContext.Consumer>
      {ctx => <WrappedComponent {...ctx} {...(props as P)} />}
    </ActivityContext.Consumer>
  );
};
