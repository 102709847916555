import { wixColorParam } from '@wix/tpa-settings/dist/src/getWixColor';
import { wixFontParam } from '@wix/tpa-settings/dist/src/getWixFont';
import { StyleParamType } from '@wix/tpa-settings/dist/src/contexts/StylesContext/StylesContext.types';

import {
  AboutDisplaySettings,
  CoverImageLayout,
  DesignSettings,
  DesignStyles,
  DiscussionsDisplaySettings,
  GeneralLayoutSettings,
  LayoutStyles,
  MediaDisplaySettings,
  MediaLayout,
  MediaLayoutSettings,
  MembersDisplaySettings,
  TextSettings,
} from './types';
import { ButtonType } from '@wix/social-groups-common/dist/src/context/app-settings/types';

export const layoutStyles: LayoutStyles = {
  general: {
    coverImageFullWidth: {
      getDefaultValue: () => false,
      name: 'coverImageFullWidth',
      type: StyleParamType.Boolean,
    },
    coverImageHeight: {
      getDefaultValue: () => 240,
      name: 'coverImageHeight',
      type: StyleParamType.Number,
    },
    layoutSpacing: {
      getDefaultValue: () => 20,
      name: 'layoutSpacing',
      type: StyleParamType.Number,
    },
    mobileCoverImageFullWidth: {
      getDefaultValue: () => false,
      name: 'mobileCoverImageFullWidth',
      type: StyleParamType.Boolean,
    },
    mobileCoverImageHeight: {
      getDefaultValue: () => 240,
      name: 'mobileCoverImageHeight',
      type: StyleParamType.Number,
    },
    mobileLayoutSpacing: {
      getDefaultValue: () => 20,
      name: 'mobileLayoutSpacing',
      type: StyleParamType.Number,
    },
  },
};

export const designStyles: DesignStyles = {
  header: {
    groupTitle: {
      color: {
        name: 'groupTitleColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-5'),
      },
      font: {
        name: 'groupTitleFont',
        type: StyleParamType.Font,
        getDefaultValue: wixFontParam('Heading-S', {
          size: 20,
        }),
      },
      mobileFontSize: {
        name: 'mobileGroupTitleFontSize',
        type: StyleParamType.Number,
        getDefaultValue: () => 20,
      },
    },
    groupSubTitle: {
      color: {
        name: 'groupSubtitleColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-5', 0.6),
      },
      font: {
        name: 'groupSubtitleFont',
        type: StyleParamType.Font,
        getDefaultValue: wixFontParam('Body-M', {
          size: 14,
        }),
      },
      mobileFontSize: {
        name: 'mobileGroupSubtitleFontSize',
        type: StyleParamType.Number,
        getDefaultValue: () => 14,
      },
    },
    tabs: {
      color: {
        name: 'tabsColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-5'),
      },
      font: {
        name: 'tabsFont',
        type: StyleParamType.Font,
        getDefaultValue: wixFontParam('Body-M'),
      },
      mobileFontSize: {
        name: 'mobileTabsFontSize',
        type: StyleParamType.Number,
        getDefaultValue: () => 16,
      },
    },
  },
  posts: {
    background: {
      color: {
        name: 'postsBackgroundColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-1'),
      },
    },
    border: {
      color: {
        name: 'postsBorderColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-5', 0.6),
      },
      width: {
        name: 'postsBorderWidth',
        type: StyleParamType.Number,
        getDefaultValue: () => 1,
      },
    },
    text: {
      color: {
        name: 'postsTextColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-5'),
      },
      font: {
        name: 'postsTextFont',
        type: StyleParamType.Font,
        getDefaultValue: wixFontParam('Body-M', {
          size: 16,
        }),
      },
      mobileFontSize: {
        name: 'mobilePostsTextFontSize',
        type: StyleParamType.Number,
        getDefaultValue: () => 16,
      },
    },
    createNewPost: {
      color: {
        name: 'createNewPostColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-5', 0.6),
      },
      font: {
        name: 'createNewPostFont',
        type: StyleParamType.Font,
        getDefaultValue: wixFontParam('Body-M', {
          size: 14,
        }),
      },
    },
    memberName: {
      color: {
        name: 'memberNameColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-5'),
      },
      font: {
        name: 'memberNameFont',
        type: StyleParamType.Font,
        getDefaultValue: wixFontParam('Body-M', {
          size: 16,
        }),
      },
    },
    postingDate: {
      color: {
        name: 'postingDateColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-5'),
      },
      font: {
        name: 'postingDateFont',
        type: StyleParamType.Font,
        getDefaultValue: wixFontParam('Body-M', {
          size: 14,
        }),
      },
    },
    commentBox: {
      color: {
        name: 'commentBoxColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-3'),
      },
    },
    commentsReactionsCounter: {
      color: {
        name: 'commentsReactionsCounterColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-5'),
      },
      font: {
        name: 'commentsReactionsCounterFont',
        type: StyleParamType.Font,
        getDefaultValue: wixFontParam('Body-M', {
          size: 14,
        }),
      },
    },
  },
  buttons: {
    text: {
      color: {
        name: 'buttonTextColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-1'),
      },
      font: {
        name: 'buttonTextFont',
        type: StyleParamType.Font,
        getDefaultValue: wixFontParam('Body-L', {
          size: 16,
        }),
      },
    },
    color: {
      name: 'buttonColor',
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-8'),
    },
    radius: {
      name: 'buttonBorderRadius',
      type: StyleParamType.Number,
      getDefaultValue: () => 100,
    },
  },
  textButtons: {
    color: {
      name: 'textButtonColor',
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-8'),
    },
    font: {
      name: 'textButtonFont',
      type: StyleParamType.Font,
      getDefaultValue: wixFontParam('Body-M', {
        size: 16,
      }),
    },
  },
  overlay: {
    color: {
      name: 'overlayColor',
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5', 0.6),
    },
    icon: {
      color: {
        name: 'iconOverlayColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-1'),
      },
    },
    groupHeaderIcons: {
      color: {
        name: 'groupHeaderIconsColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-5'),
      },
    },
    postIcons: {
      color: {
        name: 'postIconsColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-5'),
      },
    },
  },
  app: {
    background: {
      color: {
        name: 'appBackgroundColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-1'),
      },
    },
  },
  infoPanels: {
    about: {
      title: {
        color: {
          name: 'infoPanelsAboutTitleColor',
          type: StyleParamType.Color,
          getDefaultValue: wixColorParam('color-5'),
        },
        font: {
          name: 'infoPanelsAboutTitleFont',
          type: StyleParamType.Font,
          getDefaultValue: wixFontParam('Body-M', {
            size: 16,
            style: {
              bold: true,
              italic: false,
              underline: false,
            },
          }),
        },
      },
      subtitle: {
        color: {
          name: 'infoPanelsAboutSubtitleColor',
          type: StyleParamType.Color,
          getDefaultValue: wixColorParam('color-5'),
        },
        font: {
          name: 'infoPanelsAboutSubtitleFont',
          type: StyleParamType.Font,
          getDefaultValue: wixFontParam('Body-M', {
            size: 16,
          }),
        },
      },
    },
    members: {
      title: {
        color: {
          name: 'infoPanelsMembersTitleColor',
          type: StyleParamType.Color,
          getDefaultValue: wixColorParam('color-5'),
        },
        font: {
          name: 'infoPanelsMembersTitleFont',
          type: StyleParamType.Font,
          getDefaultValue: wixFontParam('Body-M', {
            size: 16,
            style: {
              bold: true,
              italic: false,
              underline: false,
            },
          }),
        },
      },
      subtitle: {
        color: {
          name: 'infoPanelsMembersSubtitleColor',
          type: StyleParamType.Color,
          getDefaultValue: wixColorParam('color-5'),
        },
        font: {
          name: 'infoPanelsMembersSubtitleFont',
          type: StyleParamType.Font,
          getDefaultValue: wixFontParam('Body-M', {
            size: 16,
          }),
        },
      },
    },
  },
  tabsDividers: {
    selectedTabWidth: {
      name: 'selectedTabWidth',
      type: StyleParamType.Number,
      getDefaultValue: () => 3,
    },
    selectedTabColor: {
      name: 'selectedTabColor',
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-8'),
    },
    dividerWidth: {
      name: 'dividerTabWidth',
      type: StyleParamType.Number,
      getDefaultValue: () => 1,
    },
    dividerColor: {
      name: 'dividerTabColor',
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-3'),
    },
  },
};

export const designSettings: DesignSettings = {
  buttonType: {
    key: 'buttonType',
    getDefaultValue: () => ButtonType.rectangle,
  },
};

export const generalLayoutSettings: GeneralLayoutSettings = {
  coverImageFullWidth: {
    getDefaultValue: () => true,
    key: 'coverImageFullWidth',
  },
  coverImageHeight: {
    getDefaultValue: () => 240,
    key: 'coverImageHeight',
  },
  layoutSpacing: {
    getDefaultValue: () => 20,
    key: 'layoutSpacing',
  },
  coverImageLayout: {
    getDefaultValue: () => CoverImageLayout.large,
    key: 'coverImageLayout',
  },
  mobileCoverImageFullWidth: {
    getDefaultValue: () => true,
    key: 'mobileCoverImageFullWidth',
  },
  mobileCoverImageHeight: {
    getDefaultValue: () => 240,
    key: 'mobileCoverImageHeight',
  },
  mobileLayoutSpacing: {
    getDefaultValue: () => 20,
    key: 'mobileLayoutSpacing',
  },
  mobileCoverImageLayout: {
    getDefaultValue: () => CoverImageLayout.large,
    key: 'mobileCoverImageLayout',
  },
};

export const mediaLayoutSettings: MediaLayoutSettings = {
  imagesPerRow: {
    getDefaultValue: () => 5,
    key: 'imagesPerRow',
  },
  mediaLayout: {
    getDefaultValue: () => MediaLayout.grid,
    key: 'mediaLayout',
  },
  mobileMediaLayout: {
    getDefaultValue: () => MediaLayout.grid,
    key: 'mobileMediaLayout',
  },
};

export const discussionsDisplaySettings: DiscussionsDisplaySettings = {
  showBreadcrumbs: {
    key: 'showBreadcrumbs',
    getDefaultValue: () => false,
  },
  showMembersCount: {
    key: 'showMembersCount',
    getDefaultValue: () => true,
  },
  showReactions: {
    key: 'showReactions',
    getDefaultValue: () => true,
  },
  showShareButton: {
    key: 'showShareButton',
    getDefaultValue: () => false,
  },
  mobileShowBreadcrumbs: {
    key: 'mobileShowBreadcrumbs',
    getDefaultValue: () => false,
  },
  mobileShowMembersCount: {
    key: 'mobileShowMembersCount',
    getDefaultValue: () => true,
  },
  mobileShowReactions: {
    key: 'mobileShowReactions',
    getDefaultValue: () => true,
  },
  mobileShowShareButton: {
    key: 'mobileShowShareButton',
    getDefaultValue: () => false,
  },
};

export const mediaDisplaySettings: MediaDisplaySettings = {
  showNameOnHover: {
    key: 'showNameOnHover',
    getDefaultValue: () => true,
  },
  mobileShowNameOnHover: {
    key: 'mobileShowNameOnHover',
    getDefaultValue: () => true,
  },
};

export const membersDisplaySettings: MembersDisplaySettings = {
  showContent: {
    key: 'showContent',
    getDefaultValue: () => false,
  },
  showFilters: {
    key: 'showFilters',
    getDefaultValue: () => false,
  },
  showFollowButton: {
    key: 'showFollowButton',
    getDefaultValue: () => false,
  },
};

export const aboutDisplaySettings: AboutDisplaySettings = {
  showActivity: {
    getDefaultValue: () => true,
    key: 'showActivity',
  },
  mobileShowActivity: {
    getDefaultValue: () => true,
    key: 'mobileShowActivity',
  },
};

export const textSettings: TextSettings = {
  newPostBoxLabel: {
    getDefaultValue: () => '',
    key: 'newPostBoxLabel',
  },
};
