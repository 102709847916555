import * as React from 'react';

import { translate, InjectedTranslateProps } from 'react-i18next';

import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { Box, Button } from '@wix/social-groups-common/dist/src/components';

import styles from './FirstPost.st.css';

export interface FirstPostProps {
  onClick(): void;
}

export const FirstPostComponent: React.FC<FirstPostProps &
  InjectedTranslateProps> = ({ onClick, t }) => (
  <Box {...styles(styles.root)}>
    <Text
      className={styles.title}
      tagName="h2"
      typography={TYPOGRAPHY.smallTitle}
    >
      {t('groups-web.discussion.first.title')}
    </Text>
    <Text tagName="p" {...styles(styles.description)}>
      {t('groups-web.discussion.first.description')}
    </Text>
    <Button onClick={onClick} priority={PRIORITY.primary}>
      {t('groups-web.discussion.first.button')}
    </Button>
  </Box>
);

export const FirstPost = translate()(FirstPostComponent) as React.ComponentType<
  FirstPostProps
>;
