import * as React from 'react';
import { GroupActionsContext } from './GroupActionsContext';
import { GroupContextConsumer } from './GroupContext';
import { UploadProps } from '@wix/social-groups-common/dist/src/components/ContentEditor/UploadFile';
export interface WithUploadProps extends UploadProps {}
export const withUploadAction = <P extends WithUploadProps>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof WithUploadProps>> => props => (
  <GroupContextConsumer>
    {({ uploadedRegistry }) => (
      <GroupActionsContext.Consumer>
        {({ uploadFiles, setEditMode }) => (
          <WrappedComponent
            uploadFiles={uploadFiles}
            uploadedRegistry={uploadedRegistry}
            setEditMode={setEditMode}
            {...(props as P)}
          />
        )}
      </GroupActionsContext.Consumer>
    )}
  </GroupContextConsumer>
);
