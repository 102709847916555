import * as React from 'react';
import {
  SiteMembers,
  MembersActions,
  SiteMembersWithActions,
  SiteMembersContext,
  PendingMembers,
} from './SiteMembers';
import { WixSiteMemberActions } from '@wix/social-groups-common/dist/src/context/members/WixSiteMembers';

export interface WithSiteMembers
  extends MembersActions,
    WixSiteMemberActions,
    SiteMembers,
    PendingMembers {}

export const withSiteMembers = <P extends WithSiteMembers>(
  WrappedComponent: React.ComponentType<P>,
) => props => {
  return (
    <SiteMembersContext.Consumer>
      {({ membersActions, ...restProps }: SiteMembersWithActions) => (
        <WrappedComponent {...props} {...membersActions} {...restProps} />
      )}
    </SiteMembersContext.Consumer>
  );
};
